.bgDark {
  background: #212121;
}

.m20 {
  margin-left: 20px;
  margin-right: 20px;
}

.container {
  @media only screen and (max-width: 680px) {
    width: 100%;
  }

  margin-left: auto;
  margin-right: auto;
  max-width: 680px;
}
